import React from "react";

import { Container, Navbar as BsNavbar, Nav } from "react-bootstrap";

import { Link } from "react-router-dom";

import "./Navbar.css";

export default class Navbar extends React.Component {
  render() {
    return (
      <BsNavbar collapseOnSelect variant="dark" bg="dark" expand="md">
        <Container fluid>
          <BsNavbar.Brand className="text-primary text-cursive">
            <Link to="" className="text-decoration-none">
              Pleine Lune
            </Link>
          </BsNavbar.Brand>
          <BsNavbar.Toggle
            className="border-0"
            aria-controls="basic-navbar-nav"
          />
          <BsNavbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link
                to=""
                className="nav-link"
                role="button"
                tabIndex={0}
              >
                Catalogo
              </Link>
            </Nav>
          </BsNavbar.Collapse>
        </Container>
      </BsNavbar>
    );
  }
}
