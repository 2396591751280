import "./coloredCircle.css";

export default function ColoredCircle({
  active,
  primaryColor,
  secondaryColor,
  onColorClicked,
}) {
  const backgroundStyle = !!secondaryColor
    ? `linear-gradient(-90deg, #${secondaryColor}, #${secondaryColor} 50%, #${primaryColor} 50%)`
    : `linear-gradient(-90deg, #${primaryColor}, #${primaryColor} 50%, #${primaryColor} 50%)`;
  return (
    <label
      className={`btn rounded-0 btn-color${active ? " active" : ""}`}
      onClick={onColorClicked}
    >
      <div
        className="colored-circle"
        style={{
          background: backgroundStyle,
        }}
      ></div>
    </label>
  );
}
