import "./ItemDetails.css";

export default function SingleItemDetails({ name, price_display }) {
  return (
    <>
      <h3 className="product-header text-dark">{name}</h3>
      <span className="product-price mt-1 mb-2 text-dark">{price_display}</span>
      <span
        className="product-stock px-2 py-1 text-primary border rounded border-dark"
        style={{ backgroundColor: "#000" }}
      >
        Sin stock
      </span>
    </>
  );
}
