import { Col, Container, Row, Spinner as BsSpinner } from "react-bootstrap";

export function Spinner() {
  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center">
        <Col xs="auto" className="my-auto">
          <BsSpinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </BsSpinner>
        </Col>
      </Row>
    </Container>
  );
}
