import { useState, useEffect, useCallback } from "react";

export const useFetchEntities = (url) => {
  const [loading, setLoading] = useState(true);
  const [entities, setEntities] = useState([]);

  const getEntities = useCallback(async () => {
    const response = await fetch(url);
    setEntities(await response.json());
    setLoading(false);
  }, [url]);

  useEffect(() => {
    getEntities();
  }, [url, getEntities]);
  return { loading, entities };
};

export const useFetchEntity = (url) => {
  const { loading, entities } = useFetchEntities(url);
  let entity;
  if (!entities) return { loading, entity };
  if (Array.isArray(entities))
    return { loading, entity: entities.length > 0 ? entities[0] : null };
  return { loading, entity: entities };
};
