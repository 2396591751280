import { Col, Container, Row } from "react-bootstrap";
import { ProductDetails } from "./ProductDetails";
import { ProductGallery } from "./gallery/ProductGallery";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/spinner/Spinner";
import { useFetchEntity } from "../../../customHooks/useFetch";
import { productGalleryUrl, productModelsUrl } from "../../../../constants";
import differenceBy from "lodash/differenceBy";

export function ProductModel() {
  const { productModelId } = useParams();
  const [selectedColor, setSelectedColor] = useState(null);
  const [allColorOptions, setAllColorOptions] = useState([]);
  const { loading, entity: productModel } = useFetchEntity(
    `${productModelsUrl}${productModelId}/`
  );
  const [galleryUrl, setGalleryUrl] = useState(
    `${productGalleryUrl}?productModelId=${productModelId}&takeMain=true`
  );
  const { loading: galleryLoading, entity: galleryResponse } =
    useFetchEntity(galleryUrl);
  const { secondary_model } = !!productModel ? productModel : {};

  const onColorSelected = (selectedColor) => {
    const nextSelectedColor = allColorOptions.find(
      ({ color }) => color === selectedColor.colorId
    );
    !!nextSelectedColor
      ? setSelectedColor({
          colorId: nextSelectedColor.color,
          galleryId: nextSelectedColor.gallery,
        })
      : setSelectedColor(selectedColor);
  };

  useEffect(() => {
    // Fetch gallery based on the color selected
    if (!selectedColor || !selectedColor.galleryId) return;
    setGalleryUrl(`${productGalleryUrl}${selectedColor.galleryId}`);
  }, [selectedColor]);

  useEffect(() => {
    if (!productModel) return;
    const secondaryColorOptions = !!productModel.secondary_model
      ? productModel.secondary_model.colors
      : [];
    setAllColorOptions([
      ...productModel.colors,
      ...differenceBy(secondaryColorOptions, productModel.colors, "color"),
    ]);
    const mainColor =
      productModel.colors.find(({ is_main }) => is_main) ||
      productModel.colors[0];
    setSelectedColor({
      colorId: mainColor.color,
      galleryId: mainColor.galleryId,
    });
  }, [productModel]);

  if (loading) return <Spinner />;
  return (
    <Container fluid="lg" className="py-3">
      <Row>
        <Col xs="12" md="6">
          {galleryLoading ? (
            <Spinner />
          ) : (
            <ProductGallery gallery={galleryResponse} />
          )}
        </Col>

        <Col xs="12" md="6">
          <ProductDetails
            {...productModel}
            selectedColor={selectedColor}
            currentGalleryId={
              !!galleryResponse ? galleryResponse.id : undefined
            }
            onColorClicked={onColorSelected}
          />
          {!!secondary_model ? (
            <ProductDetails
              {...secondary_model}
              selectedColor={selectedColor}
              onColorClicked={onColorSelected}
              isSecondary
            />
          ) : (
            <Container>
              <Row>
                <Col>
                  <div className="item-brand"></div>
                </Col>
              </Row>
            </Container>
          )}
        </Col>
      </Row>
    </Container>
  );
}
