import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { siteUrl } from "../../../../../constants";

import "./ProductGallery.css";

const thumbnailPageSize = 3;

export function ProductGallery({ gallery }) {
  const [shownGallery, setShownGallery] = useState(null);
  const [displayUrl, setDisplayUrl] = useState(null);
  const [page, setPage] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState(1);
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(() => {
    if (!gallery) return;
    setPage(1);
    setShownGallery(gallery.urls);
    setDisplayUrl(gallery.urls[0].display_url);
    const offset = gallery.urls.length % thumbnailPageSize > 0 ? 1 : 0;
    setLastPageNumber(
      ((gallery.urls.length / thumbnailPageSize) >> 0) + offset
    );
  }, [gallery]);

  useEffect(() => {
    if (!shownGallery) return;
    setThumbnails(
      [...shownGallery].slice(
        thumbnailPageSize * (page - 1),
        thumbnailPageSize * page
      )
    );
  }, [page, shownGallery, setThumbnails]);

  return (
    <Row className="align-items-center">
      <Col xs="12" className="text-center img-display-container">
        <img
          className="img-fluid border border-dark img-display"
          src={`${siteUrl}${displayUrl}`}
          alt="Galeria"
        />
      </Col>
      <Col xs="12" className="mx-auto my-3 img-list-container">
        <Row className="align-items-center">
          <Col
            xs="auto"
            className="pr-0 perv-item"
            onClick={() =>
              setPage((prevPage) =>
                prevPage === 1 ? lastPageNumber : prevPage - 1
              )
            }
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </Col>
          <Col className="item text-center px-0">
            {thumbnails.map(({ thumbnail_url, display_url }, i) => (
              <img
                key={`thumbnail-${i}`}
                className={`img-fluid mx-1 ${
                  display_url === displayUrl ? " active" : ""
                }`}
                src={`${siteUrl}${thumbnail_url}`}
                alt="Thumbnail"
                onClick={() => {
                  setDisplayUrl(display_url);
                }}
              />
            ))}
          </Col>
          <Col
            xs="auto"
            className="pl-0 ms-auto next-item"
            onClick={() => {
              setPage((prevPage) =>
                prevPage === lastPageNumber ? 1 : prevPage + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
