import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import "./FloatingButton.css";

export default function FloatingButton() {
  return (
    <a
      href="https://wa.me/5493517510621"
      rel="noreferrer"
      id="wp-link"
      target="_blank"
      className="float shadow"
    >
      <FontAwesomeIcon icon={faWhatsapp} className="fa-lg text-primary" />
    </a>
  );
}
