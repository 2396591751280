import { useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListItem from "./ListItem";

import "./ProductList.css";

export default function ProductList({ productsPayload }) {
  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleListItemClick = useCallback(({ id }) => {
    setSelectedProductId((prevSelectedProductId) =>
      prevSelectedProductId === id ? null : id
    );
  }, []);
  if (!productsPayload) return <></>;
  const { results: products } = productsPayload;

  if (!products || products.length === 0)
    return (
      <Container fluid="md">
        <Row className="my-3">
          <Col>
            <h5 className="text-dark">No se han encontrado productos.</h5>
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container fluid="md">
      <Row className="my-3">
        {products.map((product) => (
          <Col key={`col-${product.id}`} xs="6" md="3" className="py-2 mb-4">
            <ListItem
              key={product.id}
              selected={selectedProductId === product.id}
              handleMetaClick={handleListItemClick}
              product={product}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
