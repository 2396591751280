import { faImages } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Row,
  ToggleButtonGroup,
} from "react-bootstrap";
import ColoredCircle from "../../../components/buttons/coloredCircle";

import "./ProductDetails.css";

export function ProductDetails({
  id,
  name,
  price_display,
  stock_display,
  description,
  colors,
  sizes,
  isSecondary,
  selectedColor,
  currentGalleryId,
  onColorClicked,
}) {
  const [selectedSize, setSelectedSize] = useState(null);
  return (
    <Container>
      <Row>
        <Col>
          <div className="item-brand"></div>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-between">
        <Col xs="12">
          <h1 className="my-0">{name}</h1>
        </Col>
        <Col xs="12">
          <span className="item-price">{price_display}</span>
        </Col>
        <Col xs="6" className="d-none text-right" invisible={"true"}>
          <span className="item-stock">{stock_display}</span>
        </Col>
        {!isSecondary ? (
          <Col className="cursor-pointer show-img-cont">
            <Button variant="outline-primary-dark">
              <FontAwesomeIcon icon={faImages} className="fa-md" />
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <FormGroup>
            <label className="d-block">Color</label>
            <ToggleButtonGroup
              name="colorToggle"
              className="btn-group-square btn-group-colors"
            >
              {colors.map(
                ({
                  gallery: galleryId,
                  color: colorId,
                  value: primaryColor,
                  secondary_value: secondaryColor,
                }) => (
                  <ColoredCircle
                    key={`color-${id}-${colorId}`}
                    active={selectedColor && selectedColor.colorId === colorId}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    onColorClicked={() =>
                      onColorClicked({ colorId, galleryId })
                    }
                  />
                )
              )}
            </ToggleButtonGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <FormGroup>
            <label className="d-block">Talle</label>
            <ToggleButtonGroup
              name="sizeToggle"
              className="btn-group-square btn-group-colors"
            >
              {sizes.map(({ id: sizeId, value }) => (
                <label
                  key={`size-${id}-${sizeId}`}
                  className={`btn btn-stock rounded-0${
                    sizeId === selectedSize ? " active" : ""
                  }`}
                  onClick={() => setSelectedSize(sizeId)}
                >
                  {value}
                </label>
              ))}
            </ToggleButtonGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <Card className="shadow">
            <Card.Body className="container-fluid">
              <Row>
                <Col xs="12">
                  <h4 className="my-0">Descripci&oacute;n</h4>
                </Col>
                <Col xs="12">
                  <span className="small">{description}</span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
