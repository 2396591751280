import "./Flayer.css";

export default function Flayer({ imgSource, message }) {
  return (
    <>
      <header
        className="product w-100 align-items-center justify-content-center"
        style={{
          background: `url(${imgSource}) center center`,
          backgroundSize: "cover",
          // backgroundPositionY: "-102%",
        }}
      >
        <div className="bg-layer"></div>
        <span className="text-white text-center header-quote">{message}</span>
      </header>
    </>
  );
}
