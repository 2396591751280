import { Link } from "react-router-dom";
import DualItemDetails from "./DualItemDetails";
import SingleItemDetails from "./SingleItemDetails";

import "./ListItem.css";
import { siteUrl } from "../../../../constants";

export default function ListItem({ selected, handleMetaClick, product }) {
  const { secondary_model: secondaryModel } = { ...product };
  return (
    <div className={`product${selected ? " show-details" : ""}`}>
      <Link
        to={`/${product.id}/`}
        className="text-decoration-none imgbox"
      >
        <img
          className="img-fluid border border-dark"
          src={`${siteUrl}${product.list_thumbnail}`}
          alt={product.name}
          height={205}
        />
      </Link>
      <div
        className={`product-meta with-extra${selected ? " shadow" : ""}`}
        onClick={() => handleMetaClick(product)}
      >
        {!!secondaryModel ? (
          <DualItemDetails models={[product, secondaryModel]} />
        ) : (
          <SingleItemDetails {...product} />
        )}
      </div>
    </div>
  );
}
