import "./ItemDetails.css";

export default function DualItemDetails({
  models: [primaryModel, secondaryModel],
}) {
  return (
    <>
      <div className="product-header text-dark justify-content-between d-flex justify-content-between">
        <h3 className="d-inline">{primaryModel.name}</h3>
        <h3 className="d-inline text-end">{secondaryModel.name}</h3>
      </div>
      <div className="product-price mt-1 mb-2 text-dark d-flex justify-content-between">
        <span>{primaryModel.price_display}</span>
        <span>{secondaryModel.price_display}</span>
      </div>
      <div className="product-stock d-flex justify-content-between">
        <span
          className="product-stock px-2 py-1 text-primary border rounded border-dark"
          style={{ backgroundColor: "#000" }}
        >
          Sin stock
        </span>
        <span
          className="product-stock px-2 py-1 text-primary border rounded border-dark"
          style={{ backgroundColor: "#000" }}
        >
          Sin stock
        </span>
      </div>
    </>
  );
}
