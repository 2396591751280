import React, { useState, useEffect } from "react";

import Flayer from "../../../components/flayers/Flayer";
import { useFetchEntities } from "../../../customHooks/useFetch";
import ProductList from "./ProductList";
import ProductFilters from "./filters/ProductFilters";

import Pagination from "../../../components/pagination/Pagination";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  productModelsUrl,
  categoriesUrl,
  tagsUrl,
  colorsUrl,
} from "../../../../constants";

const headerImage = require("../../../../assets/img/newbg.jpg");

export default function ProductListContainer() {
  const categoriesResponse = useFetchEntities(categoriesUrl);
  const tagsResponse = useFetchEntities(tagsUrl);
  const colorsResponse = useFetchEntities(colorsUrl);
  const [url, setUrl] = useState(productModelsUrl);

  const { entities, loading } = useFetchEntities(url);
  const [isFiltersVisible, setIsFiltersVisible] = useState();
  const [selectedFilters, setSelectedFilters] = useState({
    categories: null,
    tags: [],
    colors: [],
  });

  useEffect(() => {
    const { categories, tags, colors } = selectedFilters;
    setUrl(
      `${productModelsUrl}?category=${categories}&tags=${tags}&colors=${colors}`
    );
  }, [selectedFilters]);

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Flayer
        message="Nothing is sexier than confidence"
        imgSource={headerImage}
      />
      {isFiltersVisible &&
      !(
        categoriesResponse.loading ||
        tagsResponse.loading ||
        colorsResponse.loading
      ) ? (
        <ProductFilters
          selectedFilters={selectedFilters}
          categories={categoriesResponse.entities}
          tags={tagsResponse.entities}
          colors={colorsResponse.entities}
          onClose={(newFilters) => {
            setIsFiltersVisible(false);
            if (newFilters) setSelectedFilters({ ...newFilters });
          }}
        />
      ) : (
        <>
          <Container fluid="md">
            <div className="w-100 sticky-top bg-light my-3">
              <button
                className="btn btn-transparent text-dark w-100 text-center filter"
                onClick={() => setIsFiltersVisible((prevState) => !prevState)}
              >
                <small>
                  <FontAwesomeIcon icon={faFilter} /> Filtros
                </small>
              </button>
            </div>
          </Container>
          <ProductList productsPayload={entities} />
          <Pagination {...entities} handlePaginationClick={setUrl} />
        </>
      )}
    </>
  );
}
