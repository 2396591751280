import { Nav, PageItem, Pagination as BSPagination } from "react-bootstrap";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Pagination({ next, previous, handlePaginationClick }) {
  const currentPage = !!next
    ? +next.split("page=")[1].split("&")[0] - 1
    : !!previous
    ? +previous.split("page=")[1].split("&")[0] + 1
    : 1;

  // TODO: Find a way to get 2 number before and 2 after
  // IDEA: Use the total count and the current count to calculate if there are 1
  // or 2 pages at least before/after current page
  return (
    <>
      <Nav
        aria-label="Pleine Lune page navigation"
        className="justify-content-center"
      >
        <BSPagination>
          {!!previous ? (
            <PageItem
              tabIndex="-1"
              aria-disabled="true"
              onClick={() => {
                handlePaginationClick(previous);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </PageItem>
          ) : (
            <></>
          )}
          <PageItem className="disabled">{currentPage}</PageItem>
          {!!next ? (
            <PageItem
              tabIndex="-1"
              aria-disabled="true"
              onClick={() => {
                handlePaginationClick(next);
              }}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </PageItem>
          ) : (
            <></>
          )}
        </BSPagination>
      </Nav>
    </>
  );
}
