import { useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  FormSelect,
  Row,
  ToggleButtonGroup,
} from "react-bootstrap";
import ColoredCircle from "../../../../components/buttons/coloredCircle";

import "./ProductFilters.css";

export default function ProductFilters(properties) {
  const { categories, tags, colors, selectedFilters, onClose } = properties;
  const [selectedColors, setSelectedColors] = useState(selectedFilters.colors);

  const refContainer = useRef(null);
  const tagsRefContainer = useRef(null);

  const handleOnClose = () => {
    onClose();
  };

  const clearFilters = () => {
    refContainer.current.value = "";
    tagsRefContainer.current.value = "";
    setSelectedColors([]);
  };

  const applyFilters = () => {
    onClose({
      categories: refContainer.current.value,
      tags: Array.from(
        tagsRefContainer.current.selectedOptions,
        (option) => option.value
      ),
      colors: [...selectedColors],
    });
  };

  const toggleColorSelection = (colorValue) => {
    if (selectedColors.includes(colorValue)) {
      setSelectedColors((prevColors) => [
        ...prevColors.filter((value) => value !== colorValue),
      ]);
      return;
    }
    setSelectedColors((prevColors) => [...prevColors, colorValue]);
  };

  return (
    <div className="overlay">
      <input
        type="button"
        className="closebtn text-decoration-none"
        onClick={handleOnClose}
        value="&times;"
      />

      <Container fluid className="overlay-content vstack gap-3">
        <FormGroup>
          <FormSelect
            className="form-control"
            id="categoriesFilter"
            name="categoria"
            ref={refContainer}
            defaultValue={selectedFilters.categories}
          >
            <option value="">Todas las categorias</option>
            {categories.map(({ id, slug, name }) => (
              <option key={id} value={slug}>
                {name}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
        <FormGroup>
          <select
            multiple
            className="form-control"
            id="tagsFilter"
            name="tag"
            ref={tagsRefContainer}
            defaultValue={selectedFilters.tags || []}
          >
            <option value="">Todas las etiquetas</option>
            {tags.map(({ id, slug, name }) => (
              <option key={id} value={slug}>
                {name}
              </option>
            ))}
          </select>
        </FormGroup>

        <Row>
          <Col>
            <FormGroup>
              <ToggleButtonGroup
                type="checkbox"
                className="btn-group-square btn-group-colors"
              >
                {colors.map(
                  ({
                    id,
                    value: primaryColor,
                    secondary_value: secondaryColor,
                  }) => (
                    <ColoredCircle
                      key={id}
                      active={
                        selectedColors &&
                        (selectedColors.includes(primaryColor) ||
                          selectedColors.includes(secondaryColor))
                          ? " active"
                          : ""
                      }
                      primaryColor={primaryColor}
                      secondaryColor={secondaryColor}
                      onColorClicked={() => {
                        toggleColorSelection(primaryColor);
                      }}
                    />
                  )
                )}
              </ToggleButtonGroup>
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center mt-3">
          <Col xs="auto">
            <Button variant="outline-primary" onClick={clearFilters}>
              Limpiar filtros
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="outline-primary-dark" onClick={applyFilters}>
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
