import React from "react";

import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Home from "./Home";
import Error from "./Error";
import Navbar from "./components/navbar/Navbar";
import FloatingButton from "./components/floatingLinkButton/whatsapp/FloatingButton";
import ProductListContainer from "./catalogue/product/list/ProductListContainer";
import { ProductModel } from "./catalogue/product/details/ProductModel";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="landing" element={<Home />} />
          <Route path="/" element={<Outlet />}>
            <Route index element={<ProductListContainer />} />
            <Route path=":productModelId" element={<ProductModel />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
        <FloatingButton />
      </BrowserRouter>
    );
  }
}
export default App;
