import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./Error.css";

export default function Error() {
  return (
    <>
      <Container className="mt-3 text-center">
        <Row>
          <Col>
            <h1>404!</h1>
            <span>
              Parece que te perdiste. Hace click en el boton mas abajo para
              volver al catalogo
            </span>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Link to="" className="btn btn-primary">
              Volver
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
